var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{staticClass:"form py-7 px-8",model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('p',{staticClass:"mb-6"},[_vm._v(" The opening hours that you set here will be the time slots that you allow your customers to book a table with you. ")]),_vm._l((Object.entries(_vm.business_hours)),function(ref){
var day_no = ref[0];
var day_config = ref[1];
return _c('div',{key:("day_" + day_no),staticClass:"--row py-4 fade-in",class:{ 'not-shown': _vm.loading }},[_c('div',{staticClass:"--day-name font-weight-bold mb-4 mb-md-0"},[_vm._v(" "+_vm._s(_vm.DAYS[day_no])+" ")]),(day_config.length)?_c('div',{staticClass:"--day-config"},_vm._l((day_config),function(config,idx){return _c('div',{key:(day_no + "_" + idx),staticClass:"--times"},[_c('v-select',{staticClass:"required",attrs:{"dense":"","hide-details":"","outlined":"","flat":"","items":idx === 0
              ? _vm.time_range('00:00', '24:00')
              : _vm.time_range(day_config[idx - 1].to_time, '24:00'),"label":"From","rules":[_vm.field_req],"required":""},on:{"change":function (value) { return _vm.handle_from_time_change(day_config, idx, value); }},model:{value:(day_config[idx].from_time),callback:function ($$v) {_vm.$set(day_config[idx], "from_time", $$v)},expression:"day_config[idx].from_time"}}),_c('v-select',{staticClass:"required",attrs:{"disabled":!day_config[idx].from_time,"outlined":"","dense":"","hide-details":"","flat":"","items":_vm.time_range(day_config[idx].from_time, '24:00'),"label":"To","rules":[_vm.field_req],"required":""},on:{"change":function (value) { return _vm.handle_to_time_change(day_config, idx, value); }},model:{value:(day_config[idx].to_time),callback:function ($$v) {_vm.$set(day_config[idx], "to_time", $$v)},expression:"day_config[idx].to_time"}}),_c('v-select',{attrs:{"disabled":!day_config[idx].from_time ||
            !day_config[idx].from_time ||
            !_vm.fetched_settings.interval,"outlined":"","multiple":"","dense":"","hide-details":"","flat":"","items":_vm.time_range(
              day_config[idx].from_time,
              day_config[idx].to_time,
              _vm.fetched_settings.interval * 60
            ),"label":"Booking excluded times"},on:{"change":function (value) { return _vm.handle_to_time_change(day_config, idx, value); }},model:{value:(day_config[idx].excluded_times),callback:function ($$v) {_vm.$set(day_config[idx], "excluded_times", $$v)},expression:"day_config[idx].excluded_times"}}),_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.delete_day_hours(day_no, idx)}}},[_c('v-icon',[_vm._v("mdi-close")])],1),(idx === day_config.length - 1)?_c('div',{staticClass:"mt-2 position-relative"},[_c('p',{staticClass:"mb-0 d-inline primary--text cursor-pointer duplicate"},[_vm._v(" Copy hours to "),_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-chevron-down")])],1),_c('div',{staticClass:"--mask br-8 px-2 py-3 elevation-2"},[_vm._l((Object.entries(_vm.DAYS)),function(ref){
            var _day_no = ref[0];
            var text = ref[1];
return [(_day_no !== day_no)?_c('span',{key:(day_no + "_" + text),staticClass:"py-1 px-3 br-8",on:{"click":function($event){return _vm.duplicate_day(day_no, _day_no)}}},[_vm._v(_vm._s(text))]):_vm._e()]})],2)]):_vm._e(),_c('div',[(
              idx === day_config.length - 1 &&
              day_config[day_config.length - 1].to_time !== '24:00'
            )?_c('p',{staticClass:"mt-2 mb-0 d-inline-block primary--text cursor-pointer",on:{"click":function($event){return day_config.push({
                from_time: day_config[idx].to_time,
                to: null,
              })}}},[_vm._v(" Add working hours ")]):_vm._e()])],1)}),0):_c('div',[_c('p',{staticClass:"mb-0 mr-4 d-inline primary--text cursor-pointer",on:{"click":function($event){return day_config.push({ from_time: '00:00', to_time: null })}}},[_vm._v(" Add working hours ")])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }