<template>
  <v-form class="form py-7 px-8" v-model="form_valid">
    <p class="mb-6">
      The opening hours that you set here will be the time slots that you allow
      your customers to book a table with you.
    </p>

    <div
      v-for="[day_no, day_config] in Object.entries(business_hours)"
      :key="`day_${day_no}`"
      class="--row py-4 fade-in"
      :class="{ 'not-shown': loading }"
    >
      <div class="--day-name font-weight-bold mb-4 mb-md-0">
        {{ DAYS[day_no] }}
      </div>
      <div class="--day-config" v-if="day_config.length">
        <div
          v-for="(config, idx) in day_config"
          :key="`${day_no}_${idx}`"
          class="--times"
        >
          <v-select
            class="required"
            dense
            hide-details
            outlined
            flat
            :items="
              idx === 0
                ? time_range('00:00', '24:00')
                : time_range(day_config[idx - 1].to_time, '24:00')
            "
            v-model="day_config[idx].from_time"
            label="From"
            :rules="[field_req]"
            required
            @change="(value) => handle_from_time_change(day_config, idx, value)"
          ></v-select>
          <v-select
            :disabled="!day_config[idx].from_time"
            class="required"
            outlined
            dense
            hide-details
            flat
            :items="time_range(day_config[idx].from_time, '24:00')"
            v-model="day_config[idx].to_time"
            label="To"
            :rules="[field_req]"
            required
            @change="(value) => handle_to_time_change(day_config, idx, value)"
          ></v-select>
          <v-select
            :disabled="
              !day_config[idx].from_time ||
              !day_config[idx].from_time ||
              !fetched_settings.interval
            "
            outlined
            multiple
            dense
            hide-details
            flat
            v-model="day_config[idx].excluded_times"
            :items="
              time_range(
                day_config[idx].from_time,
                day_config[idx].to_time,
                fetched_settings.interval * 60
              )
            "
            label="Booking excluded times"
            @change="(value) => handle_to_time_change(day_config, idx, value)"
          ></v-select>
          <v-btn icon color="accent" @click="delete_day_hours(day_no, idx)"
            ><v-icon>mdi-close</v-icon></v-btn
          >
          <div
            v-if="idx === day_config.length - 1"
            class="mt-2 position-relative"
          >
            <p class="mb-0 d-inline primary--text cursor-pointer duplicate">
              Copy hours to <v-icon color="primary">mdi-chevron-down</v-icon>
            </p>
            <div class="--mask br-8 px-2 py-3 elevation-2">
              <template v-for="[_day_no, text] in Object.entries(DAYS)">
                <span
                  class="py-1 px-3 br-8"
                  v-if="_day_no !== day_no"
                  :key="`${day_no}_${text}`"
                  @click="duplicate_day(day_no, _day_no)"
                  >{{ text }}</span
                ></template
              >
            </div>
          </div>
          <div>
            <p
              @click="
                day_config.push({
                  from_time: day_config[idx].to_time,
                  to: null,
                })
              "
              class="mt-2 mb-0 d-inline-block primary--text cursor-pointer"
              v-if="
                idx === day_config.length - 1 &&
                day_config[day_config.length - 1].to_time !== '24:00'
              "
            >
              Add working hours
            </p>
          </div>
        </div>
      </div>
      <div v-else>
        <p
          @click="day_config.push({ from_time: '00:00', to_time: null })"
          class="mb-0 mr-4 d-inline primary--text cursor-pointer"
        >
          Add working hours
        </p>
      </div>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import range from "lodash/range";

import { field_req } from "@/utils/form_val_rules";
import {
  get_HHmm_time_as_seconds,
  get_seconds_as_HHmm,
} from "@/utils/date_utils";
import { delete_business_hour } from "@/requests";

const DAYS = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

const BOOKING_HOURS_TYPE = "booking";

export default {
  name: "BookingSettingsWorkingHours",
  props: {
    executeUpdate: { type: Boolean, default: false },
  },
  data() {
    return {
      DAYS,
      field_req,
      form_valid: false,
      loading: true,
      hours_ids_to_delete: [],
      business_hours: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
      },
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id"]),
    ...mapState("TableBookingStore", {
      fetched_business_hours: "business_hours",
      fetched_settings: "settings",
    }),
    upsert_business_hours_payload() {
      const hours = this.business_hours;
      let payload = [];

      for (let [day_no, day_config] of Object.entries(hours)) {
        day_config.forEach((config) => {
          let id = config?.id ?? null;

          let map = {
            day_no,
            from_time: config.from_time,
            to_time: config.to_time,
            shop_id: this.shop_id,
            service_type: BOOKING_HOURS_TYPE,
            excluded_times: config.excluded_times,
          };
          if (id) map.id = id;

          payload.push(map);
        });
      }

      return payload;
    },
  },
  async mounted() {
    this.$emit("loading", true);
    await this.fetch_and_parse_business_hours();
  },
  watch: {
    form_valid(is_valid) {
      this.$emit("form-valid", is_valid);
    },
    async executeUpdate(execute) {
      if (!execute) return;
      await this.do_update();
    },
  },
  methods: {
    ...mapActions("TableBookingStore", [
      "upsert_business_hours",
      "get_business_hours",
    ]),
    async fetch_and_parse_business_hours() {
      try {
        let business_hours;
        await this.get_business_hours(this.shop_id);
        business_hours = this.fetched_business_hours ?? null;

        if (!business_hours) return;
        for (let [day_no, day_config] of Object.entries(business_hours)) {
          this.business_hours[Number(day_no)] = day_config;
        }
        setTimeout(() => {
          this.$emit("change", false);
        }, 100);
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("loading", false);
        this.loading = false;
      }
    },
    handle_from_time_change(day_config, idx, new_time) {
      // If changing "from_time" of a config row, we have to check
      // whether it doesn't overlap with same row "to_time". If so
      // it has to be cleared.
      const time = day_config[idx];
      if (time && time.to_time < new_time) {
        time.to_time = null;
      }
      this.$emit("change", true);
    },
    handle_to_time_change(day_config, idx, new_time) {
      // If changing "to_time" of a config row, we have to check
      // whether it doesn't overlap with next row "from_time". If so
      // it has to be cleared.
      const next_times = day_config[idx + 1];
      if (next_times && next_times.from_time < new_time) {
        next_times.from_time = null;
      }
      this.$emit("change", true);
    },
    time_range(from, to, interval) {
      if (!from || !to) return;
      const seconds_in_15_mins = interval || 900;
      const available_times_in_seconds = range(
        get_HHmm_time_as_seconds(from),
        get_HHmm_time_as_seconds(to) + (interval || 900),
        seconds_in_15_mins
      );
      const midnight = 86400; // 24:00 in seconds
      const last_time = available_times_in_seconds.length - 1;

      if (available_times_in_seconds[last_time] === midnight) {
        // Change last time to "23:59"
        available_times_in_seconds[last_time] = midnight - 1;
      }

      return available_times_in_seconds.map(get_seconds_as_HHmm);
    },
    async delete_day_hours(day_no, idx) {
      let id = this.business_hours[day_no][idx]?.id;
      if (id) {
        try {
          await delete_business_hour(id);
          this.$emit("show-snackbar");
        } catch (error) {
          console.error(error);
        }
      }
      this.business_hours[day_no].splice(idx, 1);
    },
    async duplicate_day(from, to) {
      const hours_copy = JSON.parse(JSON.stringify(this.business_hours[from]));

      hours_copy.forEach((config) => {
        delete config.id;
        delete config.dayconfigno;
      });

      let existing_to_day_hours = this.business_hours[to];
      const existing_ids = existing_to_day_hours
        .map((config) => {
          if (config.id) return config.id;
        })
        .filter((id) => Boolean(id));

      this.hours_ids_to_delete = [...this.hours_ids_to_delete, ...existing_ids];

      this.business_hours[to] = hours_copy;
      this.$emit("change", true);
    },
    async do_update() {
      try {
        if (this.hours_ids_to_delete.length) {
          await Promise.all(
            this.hours_ids_to_delete.map(async (id) => {
              await delete_business_hour(id);
            })
          );
          this.hours_ids_to_delete = [];
        }
        await this.upsert_business_hours(this.upsert_business_hours_payload);
        await this.fetch_and_parse_business_hours();
        this.$emit("change", false);
        this.$emit("show-snackbar");
      } catch (error) {
        console.error(error);
      } finally {
        this.$emit("update:finished");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 100%;
  .--row {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      display: grid;
      grid-template-columns: 2fr 10fr;
      grid-template-areas: "left right";
    }
    &:last-of-type {
      padding-bottom: 0;
    }
    .--day {
      grid-area: left;
    }
    .--day-config {
      grid-area: right;
    }
    .--times {
      display: grid;
      column-gap: 1rem;
      padding-bottom: 1rem;
      justify-content: center;
      grid-template-columns: 1fr;
      row-gap: 1rem;

      @media #{map-get($display-breakpoints, 'md-and-up')} {
        grid-template-columns: 3fr 3fr 6fr 1fr;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}
.duplicate {
  position: relative;

  + .--mask {
    background-color: white;
    position: absolute;
    top: 18px;
    display: none;
    flex-direction: column;
    z-index: 100;

    &:hover {
      display: flex;
    }

    span {
      margin-bottom: 5px;
      cursor: pointer;
      &:hover {
        background-color: var(--v-light-grey-base);
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &:hover {
    + .--mask {
      display: flex;
    }
  }
}
</style>
